<!-- template for the modal component -->
<template>
  <div class="modal-wrapper" v-if="show">
      <!-- Default content placed here as HTML -->
      <div class="modal-body">
        <slot name="body"></slot>
      </div>
    <div class="close">
      <close-button-icon @click="$emit('close')"/>
    </div>
  </div>
</template>

<style scoped>
.modal-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  padding: var(--spacing) 50px;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 25px;
  text-align: left;
 -webkit-overflow-scrolling: touch; /* Try to fix non-scrolling issue on iPhone */
}

.modal-body::-webkit-scrollbar { 
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.modal-body {
  font-size: 0.95em;
  max-height: 70vh;
  max-width: 650px;
  min-width: 250px;
  color: var(--color-white);
  /* overflow-y: scroll; */
  overflow: scroll;
  -webkit-overflow-scrolling: touch; /* Try to fix non-scrolling issue on iPhone */
  -ms-overflow-style: none;  /* Hide scrollbar: IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* .modal-footer {
  padding-top: 5px;
} */

.close {
  position: absolute;
  right: 15px;
  top: 15px;
}
</style>

<script>
import CloseButtonIcon from './CloseButtonIcon.vue';
// import MoreInfo from "./MoreInfo.vue";

export default {
  components: {
    CloseButtonIcon,
    // MoreInfo
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
};
</script>