<template>
    <p>For more details, refer to our paper 'Operational response simulation tool for epidemics within refugee and IDP settlements,' 
        linked <a href="https://www.unglobalpulse.org/document/operational-response-simulation-tool-for-epidemics-within-refugee-and-idp-settlements/" target="_blank" rel="noopener noreferrer">here</a>.</p>
</template>

<script>


export default {
  name: 'MoreInfo'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

p {
    padding-top: 15px;
    font-style: oblique;
    font-size: 0.95em;
}

</style>



