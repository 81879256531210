<template>
    <rect class="location" :id = "category" :x= "x" :y= "y" :width= "width" :height="height"> </rect>
    <image :href= "src" :width="width" :x="x" :y="y" :alt="category"/>
</template>

<script>

export default {
  name: 'LocationElement',
  props: { // This is what this component takes in from parent (Scene.vue)
    x: {
        type: Number,
        default: 0,
    },
    y: {
        type: Number,
        default: 0,
    },
    width: {
        type: Number,
        default: 200,
    },
    height: {
        type: Number,
        default: 150,
    },
    category: {
      type: String,
      default: 'shelter'
    }
  },
  data() {
    return{
      // Where to get the image of the location
      src: '/img/' + this.category + '.png'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.location{
    fill: white;
    opacity: 0;
}
</style>
