<template>
    <g class="agent-group" :id="name" :transform="`translate(${x}, ${y})`" :visibility="visibility">
      <circle class="agent" :class="[fillOption, status]" cx= "0" y= "0" :r= "radius" key="2"></circle>
      <circle class="nCoV" v-if="status=='infected'" :cx="(radius-1)" :cy="-(radius-1)" :r="radius/3" key="3"></circle>
    </g>
</template>

<script>
export default {
  name: 'AgentElement',
  props: { // This is what this component takes in from parent (Scene.vue)
    x: {
        type: Number,
        default: 0,
    },
    y: {
        type: Number,
        default: 0,
    },
    status: {
      type: String,
      default: "susceptible",
    },
    name: String,
    radius: {
      type: Number,
      default: 10,
    },
    hide: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return{
      //
    }
  },
  computed: {
    visibility(){
      return this.hide ? "hidden" : "visible"
    },
    fillOption(){
      return this.highlight ? "highlighted" : "normal"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.agent{
  stroke: white;
  stroke-width: 0.5; 
  stroke-opacity: 0.5;
}

/* .susceptible{
  fill: var(--darkblue); 
}

.infected{
  fill: var(--darkblue); 
} */

.agent.normal {
  fill: var(--grey); 
  opacity: 0.5;
}

.agent.highlighted { 
  fill: var(--darkblue);
  /* fill: var(--yellow); */
  opacity: 0.9;
}

.nCoV{
  fill: var(--darkred);
  opacity: 0.7;
}
.agent-group {
  transition: transform 1.5s ease; 
  /* cubic-bezier(.56,.2,.75,.88); */
}
</style>
