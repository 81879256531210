<template>
    <text text-anchor="end" :x="x" :y="y">Total infections: {{text}}</text>
</template>

<script>


export default {
  name: 'Counter',
  props:{
    x: {
        type: Number,
        default: 0,
    },
    y: {
        type: Number,
        default: 0,
    },
    text: {
        type: String,
        default: "Text"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

text {
  font-family: "Rubik", sans-serif;
  font-size: 24px;
  font-weight: 700;
  fill: white;
  /* stroke: black; */
  /* stroke-width:1px; */
}


@media (max-width: 600px) {
  text {
    font-size: 22px;
  }
}

@media (max-width: 400px) {
  text {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  text {
    font-size: 30px;
  }
}



</style>



