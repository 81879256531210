<template>
    <div class="bounce"><slot></slot></div>
</template>

<script>


export default {
  name: 'BouncingText'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes bounce {
    0%, 100% {
        transform: translateY(0px);
    }
    40%, 60% {
        transform: translateY(-4px);
    }
}

.bounce {
    -webkit-animation:bounce 2s ease-out infinite alternate;
    -moz-animation:bounce 2s ease-out infinite alternate;
    animation: bounce 2s ease-out infinite alternate;
}

</style>



