// Constant and function definitions: -----------------------

// Define activities and lambda values (can support lambda with up to 1 decimal place) 
const activityMgr = [
  {location: "distribution-center", lambda: 0.5}, // 0.027
  {location: "learning-center", lambda: 1},
  {location: "latrine", lambda: 6.91}
]

// Define beta values for each type of setting
const infectivities = [
  {interaction: "indoor", beta: 0.5},
  {interaction: "outdoor", beta: 0.05}
]

// Do some calculations needed to calculate probabilities of each activity: --------------
// Calculate sum of activity lambda values
let actSum = activityMgr.map(act => act.lambda).reduce((a,b) => a + b, 0);
// Calculate probability of leaving shelter
const actProb =  1 - Math.exp((-1) * actSum)
// Generate array of locations, with each location repeated lambda # of times
const actArray = activityMgr.map(act => Array.from({length:(act.lambda*10)}, i => act.location)).reduce((a, b) => a.concat(b), []);


// Define our mixin to Movements.vue: ----------------------
export const Activities = {
  methods: {

    // Define function to return location name that matches the activity (location type)
    pickLocation(activity) {
      const matches = this.locationList.filter(loc => loc.category == activity && isInView(loc, this.bounds))
      if (matches.length > 0){ // If there is more than one location for that activity, pick one at random
        let rand = Math.floor(Math.random() * matches.length);
        var loc = matches[rand];
        return loc.name
      } else {
        return null
      }
      // Check if center point of location is within viewbox
      function isInView(loc, bounds) {
        const center_x = loc.x + (loc.width/2)
        const center_y = loc.y + 75
        return center_x > bounds.x &&
          center_x < bounds.x + bounds.width &&
          center_y > bounds.y && 
          center_y < bounds.y + bounds.height
      }
    },
    // Get x, y coordinates from location of agent (takes in location object)
    getCoords(locationName) {
      const coords = {x: 0, y: 0}
      const loc = this.locationList.find(loc => loc.name == locationName)
      if (loc){
        const offsetX = (loc.interaction == "outdoor") ? (loc.width/2) * (Math.random() < 0.5 ? -1 : 1) : 0; // Offset left or right randomly if outdoors
        const offsetY = (loc.interaction == "outdoor") ? 50 : 0; // Offset down if outdoors
        coords.x = loc.x + (loc.width/2)  + offsetX //+ (Math.random() * 50) - 25; // Add some random noise so that we can see the individual agents
        coords.y = loc.y + 100 + offsetY //+ (Math.random() * 50) - 25;
      } else {
        // Give a random x, y given a bounding box (X,Y)
        let bounds = this.bounds
        coords.x = bounds.x + Math.floor((Math.random() * (bounds.width)));
        coords.y = bounds.x + Math.floor((Math.random() * (bounds.height)));
        console.log(`No location specified. Setting random location: ${coords.x}, ${coords.y}`)
      }
      return coords
    },

  // Calculate infection probabilities for each location
    calculateInfectionProbs(){
      let probabilities = this.locationList.map(loc => {
          // Find people who are at this exact location
          const group = this.agentList.filter(agent => agent.loc == loc.name);
          // Do some calculations to compute probabilities of infection: -------
          // Sum of infectivities the person comes in contact with
          const infectSum = group.map(agent => agent.I).reduce((a,b) => a + b, 0);
          // Calculate probability of infection
          const beta = infectivities.find(i => i.interaction == loc.interaction)['beta'];
          const infectProb = 1 - Math.exp((-1) * beta * infectSum);
          return [loc.name, infectProb]
        })
      return Object.fromEntries(probabilities)
    },
        
    // Spread infection among agents
    spreadInfection() { // !!
      let infectionProbabilities = this.calculateInfectionProbs()
      this.agentList.forEach(a => {
        a.contract(infectionProbabilities)
      })
    },
  }
}

export class Agent {
  constructor(init){
    this.id = init.id;
    this.hidden = undefined ? false : init.hidden;
    this.loc = init.loc;
    this.status = init.status;
    // this.x = init.x == undefined ? 0 : init.x; // If not specified, default to 0 (x, y, infectivity I)
    // this.y = init.y == undefined ? 0 : init.y;
    this.I = init.I == undefined ? 0 : init.I;
    this.daysInfected = -1;
  }

  // Figures out where our agent should go next:
  nextActivity(t){ // Keep it as an agent method, since might depend on agent properties later on
    // Nighttime?
    if ((t % 5) == 0){ 
      return "shelter"; // At the last timestep of the day (5 per day), everyone goes home
    }
    { // Go out?
      let rand = Math.random();
      if (rand > actProb){ // There is a (1-actProb) probability of staying home
        return "shelter";
        }
    }
    { // If you do go out, choose an activity based on actArray
      let rand = Math.floor((Math.random() * actArray.length));
      return actArray[rand];
    }
  }

  // // Sets x and y coords of an agent:
  // setCoords({x = 0, y = 0}={}){ // !!
  //   this.x = x;
  //   this.y = y;
  // }

  // Models transmission of infection (changes agent status based on some interaction probabilities)
  contract(infectionProbabilities){ // !!
    if (this.status == "susceptible"){
      { 
        let rand = Math.random();
        if (typeof(infectionProbabilities) == "number"){
          if (rand < infectionProbabilities){ // Infect with some specified probability
            this.status = "infected";
          }
        } else { // Otherwise assume probabilties are in a named object
          if (rand < infectionProbabilities[this.loc]){ // Infect at some probability specified at that location
            this.status = "infected";
          }
        }
      }
    }
    this.updateStatus();
  }

  // Updates status based on infectivity profile and number of days infected
  updateStatus(){ // !!
    if (this.status == "infected"){
      this.daysInfected ++;
      this.I = this.calculateInfectivity()
    }
  }

  calculateInfectivity(){
    if (this.daysInfected < 3){ // Arbitrary infectivity profile just so not everything is 1
      return 0.2;
    } else if (this.daysInfected < 7){
      return 0.7;
    } else {
      return 0.3;
    }
  }

  resetInfection(){
    this.status = "susceptible";
    this.I = 0;
    this.daysInfected = 0;
  }
}