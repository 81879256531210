<template>
    <text text-anchor="middle" :x="x" :y="y">{{text}}</text>
</template>

<script>


export default {
  name: 'LocationLabel',
  props:{
    x: {
        type: Number,
        default: 0,
    },
    y: {
        type: Number,
        default: 0,
    },
    text: {
        type: String,
        default: "Text"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

text {
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-weight: 300;
}

@media (max-width: 600px) {
  text {
    font-size: 24px;
  }
}

@media (max-width: 400px) {
  text {
    font-size: 28px;
  }
}

</style>



