<template>
    <svg id="data-layers" ref="layersSVG" viewBox="0 0 2120 1600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g class="layer" id="layer0" :class = "visibility[0]">
             <!-- :visibility="visibility[0]" -->
            <path d="M823.451,715.277l-670.002,386.825l572.343,330.443l670.002,-386.826l-572.343,-330.442Z" style="fill:#0074d3;fill-opacity:0.53;"/>
            <text x="1495px" y="1057.99px" style="font-family:'Rubik-Light', 'Rubik',sans-serif;font-weight:300;font-size:64px;">Geography</text>
        </g>
        <g class="layer" id="layer1" :class = "visibility[1]">
            <path d="M823.451,597.845l-670.002,386.826l572.343,330.443l670.002,-386.826l-572.343,-330.443Z" style="fill:#cf5c42;fill-opacity:0.75;"/>
            <text x="1500px" y="893.448px" style="font-family:'Rubik-Light', 'Rubik',sans-serif;font-weight:300;font-size:64px;">Locations</text>
        </g>
        <g class="layer" id="layer2" :class = "visibility[2]">
            <path d="M823.451,402.382l-670.002,386.826l572.343,330.442l670.002,-386.826l-572.343,-330.442Z" style="fill:#ffb100;fill-opacity:0.53;"/>
            <g transform="matrix(1,0,0,1,-52.666,-243.697)">
                <text x="1551px" y="941.44px" style="font-family:'Rubik-Light', 'Rubik',sans-serif;font-weight:300;font-size:64px;">Population</text>
                <text x="1551px" y="1005.44px" style="font-family:'Rubik-Light', 'Rubik',sans-serif;font-weight:300;font-size:64px;">demographics</text>
            </g>
        </g>
        <g class="layer" id="layer3" :class = "visibility[3]">
            <path d="M823.451,222.708l-670.002,386.826l572.343,330.442l670.002,-386.826l-572.343,-330.442Z" style="fill:#25a09c;fill-opacity:0.66;"/>
            <text x="1500px" y="565.538px" style="font-family:'Rubik-Bold', 'Rubik',sans-serif;font-weight:700;font-size:64px;">Data sources used:</text>
        </g>
    </svg>
</template>

<script>

const defaultVisibility = {
                            0: "hidden",
                            1: "hidden",
                            2: "hidden",
                            3: "hidden"
                            }


export default {
  name: 'DataLayers',
  props: { // This is what this component takes in from parent (App.vue)

  },
  data() {
    return{
        visibility: Object.assign({}, defaultVisibility) 
    }
  },
  methods: {
    revealLayers(){
        for (let i = 0; i <= 4; i++){
            setTimeout(() => {
                this.visibility = this.visibilitySettings(i);
            }, 300*i)
        }
    },
    hideLayers(){
        this.visibility = this.visibilitySettings(0);
    },
    setLayers(N){ // !!
        this.visibility = this.visibilitySettings(N);
    },
    visibilitySettings(N){ 
        let settings = Object.assign({}, defaultVisibility)
        for (let i = 0; i < N; i++){
            settings[i] = "visible"
        }
        return settings
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#data-layers{
    width: 80%;
    max-width: 800px;
    /* float: right; */
    margin: 0 auto;
}


.visible {
    opacity: 1;
}

.hidden {
    opacity: 0;
}

.layer {
    transition: opacity 1s ease-in-out;
}
</style>
