<template>
  <header>
    <div id="feature-wrapper" class="background">
        <div id="feature-img">
        <img
          src= "img/RF2277406_web.jpg"
          alt="Three Rohingya children overlooking the Cox's Bazar settlement, Bangladesh"
        />
        </div>
        <!-- alt="A girl and a teaching assistant, both wearing masks, at a learning centre in Cox's Bazar, Bangladesh" -->

    <div id="feature-title">
        <h1>Using computer simulations in refugee settlements:</h1>
        <h2>Diving into the epidemic models that can teach us about the spread of disease and intervention planning</h2>
        <p><BouncingText>Scroll down for the story ▼</BouncingText></p>
      </div>
    </div>

  </header>

  <main :class="{'noscroll': showModal}">
    <!-- Logic for showing the modal -->
      <story-modal id="story-modal" :class="{'hidden': !showModal}" @close="() => (showModal = false)">
        <template v-slot:body>
          <div v-html="modalContent"></div>
        </template>
      </story-modal>

    <section class="scrolly">
      <!-- Sticky figure stays stuck while in parent div (section) -->
      <figure class="scroll__background background">
        <div class="wrapper">
        <img
          src="img/RF2277606_web.jpg"
          alt="Shelters in Cox's Bazar Settlement, Bangladesh"
        />
        </div>
      </figure>
      <!-- Article overlays on top -->
      <article class="scroll__text">
        <story-card class="step">
          <p>
            The spread of COVID-19 has presented many challenges to healthcare systems worldwide.
            In settlements for refugees and internally-displaced persons (IDPs), which often suffer from overcrowding and insufficient sanitation, <strong>the rapid spread of the pandemic presents a significant threat</strong>— as will any future health crises.
          </p>
        </story-card>
        <story-card class="step" @info-request="updateModal" :extratext="true">
          <p>
            For example, <strong>the Cox’s Bazar settlement in Bangladesh</strong>, the largest of its kind in the world, houses more than 44,000 people per square kilometer. This is one and a half times higher than the density of people in New York City.
          </p>
          <template v-slot:infobox>
            <h4>Where is the Cox's Bazar settlement?</h4>
            <div class="flex-container" style="align-items: flex-start;">
            <div style="padding-right:20px;padding-bottom:20px;min-width:200px;">
                <img src="img/bangladesh_map.png" class="responsive" alt="Map of Bangladesh and surrounding area. Arrow pointing to the location of Cox's Bazar, near the border of Myanmar and Bangladesh." />
            </div>
              <div style="flex-shrink:8; min-width: 200px;">
                The Cox's Bazar settlement is located in Bangladesh. A majority of the people in the settlement are Rohingya, a stateless Muslim minority who have continuously fled targeted violence, discrimination, and human rights violations in Myanmar. Over 742 000 people arrived to the settlement after an outbreak of violence in Myanmar's Rakhine state in 2017.
              </div>
            </div>
            <MoreInfo />
          </template>
        </story-card>
        </article>
    </section>

    <section class="scrolly">
      <!-- Sticky figure stays stuck while in parent div (section) -->
      <figure class="scroll__background background fade-to-white">
        <div class="wrapper">
          <img
            src="img/RF2292365_web.jpg"
            alt="Medical personnel in blue scrubs and masks, standing outside a medical centre in the Cox's Bazar Settlement"
          />
        </div>
      </figure>
      <!-- Article overlays on top -->
      <article class="scroll__text">
        <story-card class="step" @info-request="updateModal">
          <p>
            To help inform epidemic response, public health officials, researchers, and scientists used <strong>mathematical modelling</strong> to test out how different public health interventions may affect the spread of the COVID-19 disease.
          </p>
          <p>
            Our team used the Cox’s Bazar settlement as a case study. 
          </p>
          <template v-slot:infobox>
            <h4>About the team</h4>
            <p>This project is a collaboration between: UN Global Pulse, UNHCR, UNHCR Innovation, WHO, Durham University, London School of Hygiene and Tropical Medicine, New York University, and MIT-IBM Watson AI Lab.</p>
            <p>To find out more about this collaboration, check out <a href="https://www.unglobalpulse.org/microsite/epidemic-modelling-in-settlements/" target="_blank"><strong>our project website</strong></a>.</p>
          </template>
        </story-card>
        <story-card class="step">
            <p><strong>But how does a mathematical model like this work?</strong></p>
        </story-card>
      </article>
    </section>
    <section class="scrolly">
      <!-- Sticky figure stays stuck while in parent div (section) -->
      <figure class="scroll__background">
        <div class="wrapper">
          <Scene ref="scenepreview" 
            numAgents=10 numInfected=0 background="false"
          />
        </div>
      </figure>
      <!-- Article overlays on top -->
      <article class="scroll__text" >
        <story-card class="step" data-story-type="scenepreview" data-step="anim" data-action-step="0">
          <p> Mathematical models can be complex and highly detailed since they use many different data sources and approaches. </p>
          <p> In what follows, we’ll describe how the team used <strong>agent-based modelling</strong> to simulate the spread of COVID-19. </p>

        </story-card>
        <story-card class="step">
          <p>
            In essence, we created a virtual world that mimics the way people move and what they do throughout the day, in order to understand how a virus can spread.
          </p>
        </story-card>
      </article>
    </section>

    <section class="scrolly">
      <!-- Sticky figure stays stuck while in parent div (section) -->
      <figure class="scroll__background">
          <DataLayers ref="datascene"/>
      </figure>
       <!-- Article overlays on top -->
      <article class="scroll__text">
        <!-- <story-card class="step" data-story-type="datascene" >
          <p></p>
        </story-card> -->
        <story-card class="step" data-story-type="datascene" data-step="anim" data-action-step="0" @info-request="updateModal">
          <p>
            To do this, <strong>we used data to create a ‘digital twin’</strong> of the settlement that reflects its geography and physical layout, as well as the density, age, sex, and family compositions of the people who live in it.
          </p>
          <template v-slot:infobox>
            <h3>Data sources used for this project</h3>
            <div style="padding-left:10px;">
            <h4 class="icon-heading"><img src="img/map.svg" class="icon"/>Geographical layout:</h4>
            <ul>
            <li>Maps for each of these geographical levels are used in constructing the model, with varying levels of aggregation (camp, block, sub-block)</li>
            </ul>
            <h4 class="icon-heading"><img src="img/people.svg" class="icon"/> Population and demographics:</h4>
            <ul>
            <li>Age &amp; sex distribution of people per block</li>
            <li>Number of people per sub-block</li>
            <li>Range of family sizes</li>
            </ul>
            <h4 class="icon-heading"><img src="img/camp.svg" class="icon"/> Locations:</h4>
            <ul>
            <li>Latitude &amp; longitude of locations in the settlement where people might interact</li>
            </ul>
            </div>
            <MoreInfo />
          </template>
        </story-card>
        <story-card class="step" data-story-type="datascene" data-step="anim" data-action-step="1">
          <p>
            Now let’s take a closer look at how this works and zoom into one of the shelters in our model's 'digital twin'.
          </p>
        </story-card>
      </article>
    </section>

    <section class="scrolly">
      <figure class="scroll__background background fade-to-white">
        <div class="wrapper">
        <Scene ref="mainscene" highlightThis=1 numAgents=8 numInfected=1 
          :viewHeight="sceneOptions.viewHeight"
          :viewWidth="sceneOptions.viewWidth"
          :viewX="sceneOptions.viewX"
          :viewY="sceneOptions.viewY"
          :background="sceneOptions.background"
          hideAgents="true"
        />
        </div>
      </figure>
      <article class="scroll__text">
        <story-card class="step" data-story-type="mainscene" data-step="intro" data-action-step="0" @info-request="updateModal">
          <p>This is a shelter where <span class="abdul" style="font-weight: 500">Abdul, a 35-year old man</span>, lives.</p>
          <p>Like in a typical shelter, <span class="abdul">Abdul</span> shares it with his family as well as with another household, totalling 7 people under the same roof. </p>
          <template v-slot:infobox>
            <div style="padding-left:10px;"><h3 class="icon-heading"><img src="img/household.svg" class="icon"/>Household and shelter compositions</h3></div>
            <div><p>To recreate realistic family structures, people in the model are grouped together according to their age and sex:</p></div>
            <ul>
            <li>The grouping of people into families and their distribution to shelters is approximated using a computer algorithm. This algorithm uses data obtained from household statistics and population data collected by the International Organization for Migration (IOM) and the UN High Commissioner for Refugees (UNHCR).</li><br/>
            <li>The resulting average family size is just over 4 people, and the average shelter size is 7.</li><br/>
            <li>In the Cox’s Bazar settlement, approximately 75% of the families share a shelter with another family, and this is also reflected in the model.</li>
            </ul>
            <MoreInfo />
          </template>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="overview" data-action-step="0" @info-request="updateModal">
          <p>These are some of the usual places where <span class="abdul">Abdul</span> and others  can go throughout the day.</p>
          <p>In the agent-based model, we simulate their daily routines: how they interact with each other and where they go.</p>
           <template v-slot:infobox>
            <h3>Locations</h3>
            <p>Using data collected by the Inter Sector Coordination Group (ISCG), we use latitude and longitude coordinates of each type of location in the settlement, 
              as shown in the example of one of the camps below.</p>
            <div class="flex-container">
              <div style="flex-grow: 1; min-width: 250px;">
                <p>The list of places include:</p>
                <ul>
                <li>Distribution center</li>
                <li>Non-food distribution center</li>
                <li>E-voucher outlet</li>
                <li>Communal centre</li>
                <li>Safe space for women and girls</li>
                <li>Religious center</li>
                <li>Learning center</li>
                <li>Hand water pumps and latrines</li>
                <li>Play groups</li>
                </ul>
              </div>
              <div>
                <img src="img/locations_map.png" class="responsive" style="min-width: 150px;" alt="Points on a map illustrating the number of different locations in a camp"/>
              </div>
            </div>
            <MoreInfo />
          </template>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="overview" data-action-step="1">
          <p>Every few hours, <span class="abdul">Abdul</span> goes from one location to another.</p>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="overview" data-action-step="2" @info-request="updateModal">
          <p>What <span class="abdul">Abdul</span> does when he leaves his shelter is randomly assigned by the model, but he is more likely to go to certain locations based on data about what people of his age might do.</p> 
          <p>For example, here we see <span class="abdul">Abdul</span> going to a distribution center to pick up food for his family.</p>
          <template v-slot:infobox>
            <h3>Simulating daily activities</h3>
            <p>In the model, a day is divided up into 5 time slots. For every time slot, a person is assigned an activity.</p>
            <p>The likelihood of doing a particular activity depends on the person’s age and sex, and the first time slot of each day is always spent at their shelter.</p>
            <MoreInfo />
          </template>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="contagion" data-action-step="0">
          <p>Many common areas, like distribution centers, latrines and washing facilities, are shared between multiple shelters.</p>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="contagion" data-action-step="1">
          <p>Given the density and living conditions in the settlement, it is easy for one person who becomes infected to spread the virus to others who use the same facilities at the same time. </p>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="contagion" data-action-step="2" @info-request="updateModal">
          <p>The likelihood of infecting others depends on the type of location, how infectious someone is, the length of time spent at the location, and a number of other factors</p>
          <template v-slot:infobox>
            <h3>Assumptions about how infection is transmitted</h3>
            <p>The chance of being infected is <strong>higher at certain locations than at others</strong>.</p>
            <p>In the model, interactions happen at 3 levels:</p>
            <ol>
              <li>Shelters (most intense interactions)</li>
              <li>Indoor locations (middle intensity)</li>
              <li>Outdoor locations, including hand water pumps &amp; latrines and play groups (lowest intensity)</li>
            </ol>
            <p>Being around more <strong>infectious people</strong> can also affect a person's chances of getting infected. How infectious others are depends on how long they've been infected for:</p>
            <img src="img/infectiousness.png" class="responsive" alt="Curve representing how infectious a person is over the course of their infection-- the curve goes up and then back down as time passes.">
            <MoreInfo />
          </template>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="infected" data-action-step="0">
          <p>What happens if <span class="abdul">Abdul</span> catches COVID-19?</p>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="infected" data-action-step="1" @info-request="updateModal">
          <p>He could remain asymptomatic,  or become symptomatic with symptoms that could worsen.</p>
          <p>How the disease affects him depends on a number of factors, including his age, sex, and any pre-existing conditions he has.</p>
          <template v-slot:infobox>
            <h3>Possible trajectories of COVID-19 progression</h3>
            <p>Using health data for different demographics, the model makes some assumptions about the different ways COVID-19 might progress depending 
              on the type of person in question. There is some randomness involved, but here are some sample "trajectories" of infection for different types of people.</p>
            <img src="img/trajectories.png" class="responsive" alt="Different sample trajectories of COVID-19 progression for different types of people">
            <MoreInfo />
          </template>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="final" data-action-step="0">
          <p>In the model, we keep track of what happens to <span class="abdul">Abdul</span>, and others in this digital settlement, as time passes.</p>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="final" data-action-step="1">
          <p><strong>This allows us to ask questions such as... </strong></p>
          <p>Which groups of people are at the highest risk of infection?</p>
          <p>(e.g. children or people who live in certain areas of the settlement?)</p>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="final" data-action-step="2">
          <p>If there is a disease outbreak, which parts of the settlement could be affected the most?</p>
        </story-card>
        <story-card class="step" data-story-type="mainscene" data-step="final" @info-request="updateModal">
          <p>What <strong>operational interventions</strong> could help curb the spread of the virus?</p>
          <template v-slot:infobox>
            <h3>How has this model been used?</h3>
            <p>The model was used by health professionals in the Cox’s Bazar settlement to test different intervention scenarios, like mask-wearing and opening or closing learning centers-- to read more, 
              check out <a href="https://www.unglobalpulse.org/2021/01/what-epidemic-modelling-can-teach-us-about-the-spread-of-disease-in-refugee-settlements/" target="_blank">this blog post</a>.</p>
              <MoreInfo />
          </template>
        </story-card>
      </article>
    </section>

    <section class="outro">
      <div class="outro-text">
        <p>To find out more about this agent-based model and how it was used in the Cox's Bazar settlement context, check out 
          <a href="https://www.unglobalpulse.org/microsite/epidemic-modelling-in-settlements/" target="_blank"><strong>our project website</strong></a>, or our 
          <a href="https://www.unglobalpulse.org/document/operational-response-simulation-tool-for-epidemics-within-refugee-and-idp-settlements/" target="_blank"><strong>academic paper</strong></a>.
        </p>
        <br/>
        <hr style="height:1px;border-width:0;color:gray;background-color:gray">
        </div>
        <div id="credits-logo">
          <div id="credits">
            <div class="credit-section">
            <h4>Story</h4>
            <p>Patricia Angkiriwang (<i>Data Science &amp; Visual Communications Fellow, UN Global Pulse</i>)</p>
            <p>Felicia Vacarelu (<i>Communications Specialist, UN Global Pulse</i>)</p>
            <p>Joseph Aylett-Bullock (<i>Data Scientist &amp; Researcher, UN Global Pulse; Durham University</i>)</p>
            </div>
            <div class="credit-section">
            <h4>Design, Illustrations &amp; Code</h4>
            Patricia Angkiriwang (<i>UN Global Pulse</i>), with code support from Benjamin Hoover (<i>MIT&#8209;IBM Watson&nbsp;AI&nbsp;Lab</i>)
            </div>
            <div class="credit-section">
            <h4>Additional support</h4>
            The project research team provided feedback on early storyboards and prototypes, with contributions by 
            Anjali Katta (<i>UN Global Pulse</i>), Carolina Cuesta-Lazaro (<i>Durham University</i>), Arnau Quera-Borafull (<i>Durham University</i>), Rebeca Moreno-Jimenez (<i>UNHCR Innovation</i>), Katherine Hoffmann Pham (<i>UN Global Pulse</i>), and Miguel Luengo-Oroz (<i>UN Global Pulse</i>). Photographs courtesy of UNHCR.
            </div>
          </div> 
          <!-- <hr style="height:1px;border-width:0;color:gray;background-color:gray"> -->
          <br>
          <div id="logos">
              <a href="https://www.unglobalpulse.org/" target="_blank">
                <img src="img/GP_logo_RGB.svg" width="150" alt="UN Global Pulse logo"/> 
              </a>
          </div>
        </div>
    </section>

    <!-- <section class="scrolly">
      <figure class="scroll__background">
        <div max-width="500px">
          <Scene numInfected=1 :displayStepButton="true" :background="true" />
          </div>
      </figure>
    </section> -->
  </main>
</template>

<script>
import "intersection-observer"; // for cross-browser support
import scrollama from "scrollama";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { gsap }  from "gsap";


import StoryModal from "./components/StoryModal.vue";
import StoryCard from "./components/StoryCard.vue";
import BouncingText from "./components/BouncingText.vue";
import MoreInfo from "./components/MoreInfo.vue";

import DataLayers from "./components/DataLayers.vue";
import Scene from "./components/Scene.vue";
import LocationElement from "./components/Location.vue";

import {interpolate} from "d3"
import { onActivated } from '@vue/runtime-core';
const d3 = {interpolate}

// import './assets/css/simple-grid.css';

// using d3 for convenience
// var main = d3.select("main");
// var scrolly = d3.selectAll(".scrolly");
// var figure = scrolly.select("figure");
// var article = scrolly.select("article");
// var step = d3.selectAll(".step");

// Create default locations to zoom into at each step
const viewConfig = {
  shelter: {
    viewX: -100,
    viewY: -20,
    viewWidth: 400,
    viewHeight: 300,
  },
  latrines: {
    viewX: 200,
    viewY: 100,
    viewWidth: 500,
    viewHeight: 400,
  },
  distribution: {
    viewX: 250, 
    viewY: 350, 
    viewWidth: 500,
    viewHeight: 400
  },
  all: {
    viewX: 20,
    viewY: 20,
    viewWidth: 900,
    viewHeight: 750,
  },
  zoom: {
    viewX: 400, 
    viewY: 600, 
    viewWidth: 200,
    viewHeight: 160
  }
};

const sceneConfig = {
  scenepreview: {
    anim: {
      actions: [
        scene => {
          let timesRun = 0;
          const interval = setInterval(function(){
            timesRun += 1;
            if (timesRun >= 10){
              clearInterval(interval)
            }
            scene.step() 
            }, 1000);
        }
      ],
      direction: "down"
    }
  },
  datascene: {
    anim: {
      actions: [
        (scene, progress) => {
          const N = Math.floor(d3.interpolate(0, 10)(progress)); // divide progress into 10 steps
          if (N <= 4){
            scene.setLayers(N);
          }
        },
        (scene, progress) => {
          scene.setLayers(4)
        }
      ],
      direction: "progress"
    }
  },
  mainscene: {
    intro: {
      view: "shelter",
      actions: [
        scene => {
          scene.toggleLocationLabels("off");
          scene.unhideAllExcept(0);
          scene.moveAgents(a => (a.id ==  0 ? {x: 700, y: 850} : "shelter")) // move infected agent offscreen, everyone else to shelter
        }
        // scene => scene.moveAgents(a => (a.id == 0 ? {x: 500, y: 500} : null), false)
      ],
      background: false,
      direction: "both"
    },
    overview: {
      view: "all",
      actions: [
        scene => {
          scene.toggleLocationLabels("on");
          scene.step(1, {infect: false, exception: 0});
        }, 
        scene => {
          // scene.toggleLocationLabels("off");
          scene.step(2, {infect: false, exception: 0});
        },
        scene => {
          scene.moveAgents(a => (a.id ==  0 ? {x: 700, y: 850} : null), false)  // move and hide infected agent offscreen
          scene.hideAgent(0);
          scene.step(3, {infect: false, exception: 0});
          scene.moveAgents(a => (a.id == 1 ? "distribution" : null), false); // agent-1 goes to distribution center
        }
      ],
      background: true,
      direction: "both"
    },
    contagion: {
      view: "distribution",
      actions: [
        scene => {
          scene.toggleLocationLabels("on");
          scene.unhideAgent(0);
          scene.moveAgents(a => (a.id ==  0 ? {x: 500, y: 675} : null), false); // new agent comes in (with infection)
          },
        scene => {
          scene.toggleLocationLabels("off");
          scene.uninfectAllExcept(0);
          scene.moveAgents(a => (a.id == 0 ? null : a.id == 1 ? {x: 550, y: 675} : "shelter"), false); // reposition agent-1 at distribution center
        },
        scene => {
          scene.unhideAgent(0);
          scene.moveAgents(a => (a.id == 0 ? {x: 500, y: 675} : null), false); // keep external agent at distribution center
          scene.infectAgent(1);
        }
      ],
      background: false,
      direction: "both"
    },
    infected: {
      view: "zoom",
      background: false,
      actions: [
        scene => {
          scene.moveAgents(a => (a.id ==  0 ? {x: 700, y: 850} : null), false); // move external agent offscreen
          scene.hideAgent(0);
        },
        scene => {
          scene.toggleCounter("off");
          scene.moveAgents(a => (a.id == 1 ? {x: 550, y: 675} : null), false); // [up] bring agent-1 back to distribution center
        }
      ],
      direction: "both"
    },
    final: {
      view: "all",
      actions: [
        scene => {
          scene.toggleCounter("on");
          scene.step(3, {exception: 0});
        },
        scene => {
          scene.step(4, {exception: 0});
        },
        scene => {
          scene.step(5, {exception: 0});
        }
      ],
      background: true,
      direction: "both"
    }
  }
};

export default {
  name: "App",
  components: {
    Scene,
    StoryModal,
    StoryCard,
    DataLayers,
    BouncingText,
    MoreInfo
  },
  data() {
    return {
      scroller: scrollama(),
      scroller2: scrollama(),
      // stepNumber: 0,
      // progressNumber: 0,
      data: [],
      margin: 64,
      width: 800,
      height: 600,
      showModal: false,
      reducedMotion: false,
      modalContent: null,
      currentScene: "",
      currentStory: "",
      sceneConfig,
      viewConfig,
      sceneOptions: Object.assign({...viewConfig["all"]}, {background:"transparent"})
    };
  },
  watch: {
    currentScene: function(newSceneName, oldSceneName){
      // Get if current scene changes, get scene properties
      let newScene, oldScene
      if (newSceneName && newSceneName.length){
        newScene = this.sceneConfig[this.currentStory][newSceneName]
        oldScene = this.sceneConfig[this.currentStory][oldSceneName] // whatever came before this scene

        if (newScene.view){
          if (this.reducedMotion){
            Object.assign(this.sceneOptions, this.viewConfig[newScene.view]);
          } else {
            // Animate viewbox
            // Where to animate from (allows user to speed up if needed)
            let oldSceneOptions
            if (!oldScene || !oldScene.view){ // If there's no old scene info stored
              for (var i in this.sceneConfig){ // Just take the first one in the scene config object
                oldSceneOptions = this.sceneConfig[i]; break;
              }
            } else { // otherwise, take the corresponding view
              oldSceneOptions = this.viewConfig[oldScene.view]
            }

            // Where to animate to
            const {viewX, viewY, viewWidth, viewHeight} = this.viewConfig[newScene.view];

            gsap.fromTo(this.sceneOptions, 
              oldSceneOptions,
              {
                duration: 3,
                ease: "power1.inOut",
                viewX: viewX,
                viewY: viewY,
                viewWidth: viewWidth,
                viewHeight: viewHeight
              })
          }
        } 

        if (newScene.background){
          this.sceneOptions.background = newScene.background;
        }
      }
    },
    showModal: function () {
      const el = document.querySelector("#story-modal")
      this.showModal ? disableBodyScroll(el, {reserveScrollBarGap: true}) : enableBodyScroll(el)
    }
  },
  mounted() {
    this.scroller // for scrolly text steps
      .setup({
        step: ".step",
        offset: 0.5,
        progress: true,
        debug: false,
      })
      .onStepEnter(this.onEnter)
      .onStepProgress(this.onProgress)
      .onStepExit(this.onExit);

    this.scroller2 // for background transitions
      .setup({
        step: ".background",
        offset: 0.75,
        debug: false,
      })
      .onStepEnter(this.onEnter)
      .onStepExit(this.onExit);

    this.resize();
    window.addEventListener("resize", this.resize);

    window.addEventListener("orienationchange", this.resize);

    window.addEventListener('keyup', e => {
      switch (e.code) {
        case "ArrowLeft":
          this.scrollToNext("reverse");
          break;
        case "ArrowRight":
          this.scrollToNext();
          break;
        default:
          /* don't do anything */
      }
    });

    // Reduced motion options
    // Grab the prefers reduced media query.
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");

    // Check if the media query matches or is not available.
    if (!mediaQuery || mediaQuery.matches) {
      this.reducedMotion = true;
    }

    // Ads an event listener to check for changes in the media query's value.
    mediaQuery.addEventListener("change", () => {
      if (mediaQuery.matches) {
        this.reducedMotion = true;
      }
    });
  },
  methods: {
    resize() {
      this.scroller.resize();
      this.scroller2.resize();
      this.height = window.innerHeight;
      this.width = window.innerWidth;

      document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
      // document.querySelector('#feature-img')['height'] = window.innerHeight;
    },
    scrollToNext(mode = "forward") {
      const active = document.querySelector(".step.active")
      const nodes = [...document.getElementsByClassName('step')];
      const activeIndex = nodes.indexOf(active); 

      let target;
      if (activeIndex >= 0){
        if (mode == "reverse"){
          target = nodes[activeIndex - 1];
        } else {
          target = nodes[activeIndex + 1];
        }
      }

      if (target){
        target.classList.add('active'); 
        this.scrollToElement(target);
        if (active){
          active.classList.remove('active');
        }
      }

    },
    scrollToElement(el) {
      if (el) {
        el.scrollIntoView({block: "center", inline: "nearest"});
        // el.scrollIntoView(true);
      }
    },
    onEnter({element, direction, index}) {
      // this.stepNumber = response.index;

      element.classList.add('active');

      // if (element.getAttribute("id") == "feature-img"){
        if (direction == "up"){
          element.classList.remove("hidden-element")
        }
      // }
      
      const storyType = element.getAttribute("data-story-type");
      if (storyType) { // if storyType exists
        this.currentStory = storyType;
        this.currentScene = element.getAttribute("data-step"); // Set current scene, which sets the view and background
        const scene = sceneConfig[this.currentStory][this.currentScene]; // Get scene view and actions
        
        if (scene){
          const executeAction = (scene.direction == "both") || (scene.direction == direction)

          // Do the action
          if ("actions" in scene && executeAction) {
            const actionStep = +element.getAttribute("data-action-step"); // Which action step to play?
            if (scene.actions.length > 0){
              const action = scene.actions[actionStep]
              // console.log(this.$refs[storyType])
              action(this.$refs[storyType], element)
            }
          }
        }
      }
    },
    onProgress(response) {
      // console.log('progress', response)

      let {element, index, progress} = response;
      const storyType = element.getAttribute("data-story-type");
      if (storyType) { 
        this.currentStory = storyType;
        this.currentScene = element.getAttribute("data-step"); 
        const scene = sceneConfig[this.currentStory][this.currentScene]; 
        if (scene){
          const executeAction = (scene.direction == "progress")

          if ("actions" in scene && executeAction) {
            const actionStep = +element.getAttribute("data-action-step"); 
            if (scene.actions.length > 0){
              const action = scene.actions[actionStep]
              action(this.$refs[storyType], progress, element)
            }
          }
        }
      }
    },
    onExit({element, direction, index}) {
      // console.log("exit", response);
      element.classList.remove('active');

      // if (element.getAttribute("id") == "feature-img"){
        if (direction == "down"){
          element.classList.add("hidden-element")
        } 
      // this.stepNumber = response.index;
    },
    updateModal(info) {
      this.modalContent = info;
      this.showModal = true;
    },
  }
};
</script>

<style>
/* Load google fonts */
@import url('https://fonts.googleapis.com/css2?family=Literata:ital,wght@0,400..700;1,400..700&family=Rubik:wght@200;300;400;500;600;700&;display=swap');
/* Can also be done in header with: */
/* <link rel="preconnect" href='https://fonts.googleapis.com/css2?family=Literata:ital,wght@0,400..700;1,400..700&family=Rubik:wght@200;300;400;500;600;700&;display=swap' rel='stylesheet' type='text/css'> */

.hidden {
  display: none;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#app {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Literata", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-dark);
  /* background-color: var(--color-dark); */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik", sans-serif;
  font-weight: bold;
}

.abdul {
  /* font-weight: 500; */
  color: var(--darkblue);
}

/* header {
  margin-bottom: 8rem;
} */

main {
  max-width: 968px;
  margin: 0 auto;
}

section {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
  max-width: var(--max-width);
  padding: var(--spacing) var(--spacing) 0 var(--spacing);
  /* hyphens: auto; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

section.scrolly {
  padding: 0;
  margin-bottom: 0;
  /* padding: var(--spacing) 0 0 0; */
}

section.scrolly:nth-of-type(1) {
  padding: 0;
}

.step:nth-of-type(1) {
  margin-top: -50%;
}


.scroll__text .step {
  margin: 0 0 50% 0;
  transition: opacity ease-out 0.2s;
}

.scroll__text {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  position: relative;
  text-align: left;
  margin-left: 2em;
}


/* .scroll__text:nth-child(1){
  margin-top: -50%;
} */

@media only screen and (max-width: 25em) {
  .scroll__text {
    width: -webkit-calc(100% - 1em);
    width:    -moz-calc(100% - 1em);
    width:         calc(100% - 1em);
    margin-left: 1em;
  }
}

.scroll__background {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  width: 100%;
  max-width: var(--max-width);
  overflow: hidden;
  margin: 0;
  top: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.wrapper {
  width: 100%;
  height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

.wrapper img {
  min-width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.flex-container {
  display:flex;
  flex-direction: row;
}


@media (max-width: 800px) {
  .flex-container {
    flex-direction: column;
  }
}



#feature-wrapper {
  margin: 0;
  width: 100%;
}

#feature-img {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  margin: 0;
  /* transition: transform ease-in 1s; */
}

#feature-img img {
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  margin: auto; 
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
}


#feature-title {
  position: absolute;
  bottom: 10%;
  left: 5%;
  color: var(--color-white);
  padding: var(--spacing);
  font-weight: 600;
  max-width: 800px;
  text-align: left;
  text-shadow: 5px 5px 10px black;
}

#feature-title h1 {
  font-size: 2.5rem;
  line-height: 0.95;
}

@media only screen and (min-width: 800px) and (min-height: 600px) {
  #feature-title { 
    padding-bottom: 10%;
    left: 10%;
    right: 10%;
  }
  #feature-title h1 { 
    font-size: 3.5rem;
  }

  .scroll__background {
    min-width: 100vw;
    /* margin: 2em; */
    top: 0;
  }
}

@media only screen and (max-height: 550px), screen and (max-width: 480px){
  #feature-title h2 { 
    font-size: 1rem;
  }

  #feature-title h1 { 
    font-size: 2rem;
  }

  #feature-title { 
    padding-bottom: 2%;
  }
}

#feature-title p {
  text-shadow: 2px 2px 5px black;
}


.hidden-element.step{
  opacity: 0;
}

.background {
  background: black;
}

.background.fade-to-white {
  background: white;
}

.hidden-element.background {
  opacity: 1;
}

.hidden-element.background .wrapper {
  opacity: 0;
  transition: opacity ease-out 1s;
  /* transform: translate3d(0, -20%, 0); */
}

.active.background .wrapper {
  opacity: 1;
  transition: opacity ease 0.3s;
}

p {
  max-width: 45em;
  text-align: left;
  line-height: 1.5;
}

.outro-text {
  margin: 0 auto; 
}

#credits-logo {
  display:flex;
  flex-direction: row-reverse;
  margin-top: 2em;
  font-size: 0.9em;
}

@media (max-width: 600px) {
  #credits-logo {
    flex-direction: column;
  }
  #logos {
    align-items: center;
  }
}

#credits {
  text-align: left;
  padding-top: 1.5em;
  padding-bottom: 3em;
}

#credits h4 {
  font-size: 1.1em;
  margin: 0;
  padding-bottom: 0.5em;
}

#credits .credit-section {
  padding-bottom: 1.2em;
}

#credits p{
  margin-top: 0em;
  margin-bottom: 0.2em;
  line-height: 1.2;
  text-indent:-25px;
  margin-left:25px;
}

#logos img {
  padding-top: 1.5em;
  padding-right: 2em;
}

.outro-text, .modal-container, .story-card {
  font-size: 1em;
}

@media only screen and (min-width: 1200px) {
  .outro-text, .modal-container, .story-card {
    font-size: 1.2em;
  }
}

a {
  color: inherit;
}

img.responsive {
  width: 100%;
  height: auto;
}

.icon {
  width: 35px; 
  height: auto;
  position: relative;
  top: 10px;
  right: 10px;
}

.icon-heading {
  text-indent:-37px; 
  margin-left:37px;
}

.outro {
  min-height: calc(var(--vh, 1vh) * 100);
  padding-top: 4rem;
  padding-bottom: var(--spacing);
}



/* .step:nth-last-child(1) {
  margin-bottom: 50vh;
} */


:root {
  --gpblue: #00aeef;
  --darkred: #9b4531;
  --yellow: #ffb100;
  --orange: #cf8236;
  --brightblue: #007be0;
  --olive: #665e1e;
  --teal: #007be0;
  --darkblue: #00447c;
  --lightred: #cf5c42;
  --grey: #5d6263;
  --lightgrey: #96999b;
  --lightblue: #e1f4fd;
  --color-white: #fff;
  --color-dark: #2c3e50;
  --spacing: 2rem;
  --max-width: 1200px;
}

</style>