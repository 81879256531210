<template>
  <div class="story-card">
    <div class="content">
        <div class="alert" v-if="extratext">
            <BouncingText>Click me for more info ▼</BouncingText>
        </div>
        <slot>Default content</slot>
        <info-icon class="info-icon" v-if="hasInfo" @click="$emit('info-request', info)"/>
        <div ref="infobox" class="infobox hidden">
            <slot name="infobox"></slot>
            <slot name="infofooter"></slot>
        </div>
    </div>
  </div>
</template>

<script>
import InfoIcon from "./InfoIcon.vue"
import BouncingText from "./BouncingText.vue";

export default {
    props: {
        extratext: {
            type: Boolean,
            default: false
        }
    },
    components: {
        InfoIcon,
        BouncingText
    },
    data() {
        return {
            info: ""
        }
    },
    computed: {
        hasInfo() {
            return this.info.length > 0
        }
    },
    mounted() {
        // Check if info was provided as a slot
        const info = this.$refs.infobox.innerHTML
        this.info = info
    }
}
</script>

<style scoped>
.hidden {
    display: none;
}

.content {
    padding: var(--spacing);
    background-color: rgba(255, 255, 255, 0.9);
    font-weight: normal;
    position: absolute;
    top: 30%;
}

.story-card {
    position: relative;
    width: 100%;
    max-width: 300px;
    min-height: 600px;
    height: 60%;
}

.info-icon {
    position: absolute;
    right: 15px;
    top: 15px;
}

.alert {
    position: absolute;
    right: 15px;
    top: -23px;
    color: white;
    font-weight: 600;
    font-family: "Rubik", sans-serif;
    text-transform: uppercase;
    font-size: 0.8em;
}

@media (min-width: 1200px) {
    .story-card {
        max-width: 500px;
    }
}

</style>