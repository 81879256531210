<template>
        <div class="vis-wrapper" :style="`background: ${backgroundColour}`">
            <!-- Create SVG -->
            <svg id="main-vis" width="100%" height="100%" aria-labelledby="mainTitle mainDesc" :style="`background-color: ${backgroundColour}`">
              <svg :viewBox ="viewBoxString" width="100%" height="100%" preserveAspectRatio="xMidYMin meet">
                <title id="mainTitle">Agent-based modelling in settlements</title>
                <desc id="mainDesc">A scene illustrating how agents move between locations in an epidemic model of a refugee/IDP settlement.</desc>

                <!-- Create locations (width/height optional here) -->
                <g class = "locations">
                  <LocationElement v-for="l in locationList" :key="l.name" :category="l.category" :x="l.x" :y="l.y" :width="l.width" :height="l.height"/>
                </g>
                <!-- Create agents -->
                <g class = "agents" ref="agents">
                  <!-- v-for="(a,i) in agentList" ... agentPositions[i] -->
                    <AgentElement v-for="(a, i) in agentList" :highlight="(highlightThis && a.id == highlightThis)" :key=i :id="'agent-'+a.id" :status="a.status" :x="agentPositions[i].x" :y="agentPositions[i].y" :radius="agentRadius" :hide="a.hidden" :location="a.loc"/>
                </g>
              <g v-show="displayLabels">
                <LocationLabel v-for="item in locationLabels" :key="item.label" :x="item.x" :y="item.y" :text="item.label"/>
                <!-- <LocationLabel v-for="item in locationLabels" :key="item.label" :x="`${(item.x - viewX)/(viewWidth - viewX)*100}%`" :y="`${(item.y - viewY)/(viewHeight - viewY)*100}%`" :text="item.label"/> -->
              </g>
              </svg>
              <g v-if="displayCounter" x=0 y=0>
                <!-- Hack for scrollytelling piece: subtract by one (the agent outside the shelter originally infected) -->
                <Counter x="90%" y="100" :text="infectedCounter - 1" /> 
                <!-- <Counter :x="viewX+viewWidth - 100" :y="viewY + 100" :text="infectedCounter - 1" />  -->
              </g>
            </svg> 
        </div>
          <!-- Create button to test step -->
          <button v-if="displayStepButton" @click="step">Step forward in time</button>
          <button v-if="displayStepButton" @click="reset">Reset</button>
</template>

<script>

// Import components of the vis
import AgentElement from './Agent.vue';
import LocationElement from './Location.vue';
import LocationLabel from './LocationLabel.vue';
import Counter from './Counter.vue';
// Import agent class / activity manager
import {Activities, Agent} from './Activities.js' // Includes functions: step, moveAgents, etc.

import * as d3 from "d3-force";

// Define colours for the sky background
const skyPalette = ["#334258","#aabdca", "#7fa1c9","#ddd5ca","#c08975"];

// Define locations 
const defaultLocationList = [{name:"learning-center", category: "learning-center", x: 100, y: 500, width: 300, interaction: "indoor"},
                      {name: "shelter", category: "shelter", x: 50, y: 50, width: 200, interaction: "indoor"},
                      {name: "distribution", category: "distribution-center", x: 550, y: 400, width: 400, interaction: "indoor"},
                      {name: "latrine-1", category: "latrine", x: 350, y: 280, width: 100, interaction: "outdoor"},
                      {name: "latrine-2", category: "latrine", x: 450, y: 250, width: 100, interaction: "outdoor"}]


// Define locations labels
const defaultLocationLabels = [{label:"Learning Center", x: 330, y: 525},
                      {label: "Shelter", x: 220, y: 200},
                      {label: "Distribution Center", x: 620, y: 725},
                      {label: "Latrines", x: 610, y: 330}]

export default {
  name: 'Scene',
  mixins: [Activities], // Take in methods and data from Activities.js
  props: { // This is what this component takes in from parent (App.vue)
    viewHeight: {
      type: Number,
      default: 800,
    },
    viewWidth: {
      type: Number,
      default: 1000
    },
    viewX: {
      type: Number,
      default: 0,
    },
    viewY: {
      type: Number,
      default: 0
    },
    displayStepButton:{
      type: Boolean,
      default: false
    },
    hideAgents: {
      type: Boolean,
      default: false
    },
    numInfected: {
      type: Number,
      default: 1
    },
    numAgents: {
      type: Number,
      default: 10
    },
    initialActivity: {
      type: String,
      default: 'shelter'
    },
    background: {
      type: Boolean,
      default: false
    },
    agentRadius: {
      type: Number,
      default: 10
    },
    highlightThis: {
      type: Number,
      default: null
    },
    locationList:{
      type: Array,
      default: function(){
        return defaultLocationList
      }
    },
    locationLabels:{
      type: Array,
      default: function(){
        return defaultLocationLabels
      }
    },
  },
  components: { // The components that get called in the template
    AgentElement,
    LocationElement,
    LocationLabel,
    Counter
  },
  data() { // This data becomes available in this instance
    return{
      agentList: [],
      timeStep: 0,
      positioning: null,
      agentPositions: [],
      displayLabels: false,
      displayCounter: false
    }
  },
  computed: { // These values are reactive
    backgroundColour(){
      if (this.background == true){
        return skyPalette[this.timeStep % skyPalette.length];
      } else {
        // console.log("no background")
        return 'transparent';
      }
    },
    viewBoxString() {
      return `${this.viewX} ${this.viewY} ${this.viewWidth} ${this.viewHeight}`
    },
    bounds() {
      return {x: this.viewX, y: this.viewY, width: this.viewWidth, height: this.viewHeight}
    },
    infectedCounter(){
      return this.agentList.filter(a => a.status == "infected").length // count number of infections in real time
    }
  },
  mounted() {
    // Set list of starting agents (see method below to customize)
    this.agentList = this.generateStartingAgents();
    // this.agentList = this.generateAgents({num_agents: this.numAgents, initial_activity: "none"}), // or can generate agents with a random chance of infection

    // Store positions
    this.agentPositions = this.agentList.map(a => {
      return {x: null, y: null}
    })

    // Use d3-force to adjust agentPositions
    this.positioning  = d3.forceSimulation(this.agentPositions)
      .stop()

    this.moveAgents(a => a.loc)
  },
  methods: { // Methods governing agent actions are found in Activities mixin (Activities.js)
    // Generate list of starting agents from initial conditions
    generateStartingAgents(){
      return Array.from({length: this.numAgents}, (_,i) => ( 
      new Agent({id: i, 
        loc: this.pickLocation(this.initialActivity), 
        status: (i < this.numInfected ? "infected" : "susceptible"),
        hidden: this.hideAgents
        })
      ))
    },

    // Generate array of Agents, each with probability p_infected of carrying virus
    // If initial_activity is "none" (or anything else that isn't in the location list), loc will be set to null
    generateAgents({p_infected = 0.05, num_agents = 20, initial_activity = "shelter"}={}){
      return Array.from({length: num_agents}, (_,i) => new Agent(
        {id: i, loc: this.pickLocation(initial_activity), status: (Math.random() < p_infected ? "infected" : "susceptible")}))
    },

    // Step forward in time (or to a certain timestep)
    step(t = null, {infect = true, exception = "none"} = {}) { // !!
        if (typeof t == 'number'){
          // Use t if provided
          this.timeStep = t;
        } else {
         // Add to timestep
          this.timeStep ++;
        }
      
        this.moveAgents(a => a.id != exception ? this.pickLocation(a.nextActivity(this.timeStep)): null);
        if (infect){
          // After move transition ends, check for infection transmission
          const transition = this.$refs.agents; //  When using refs, still works when there are multiple instances of Movements.vue (as opposed to document.querySelector('.agents'))
          transition.addEventListener('transitionend', () => {
            this.spreadInfection();
          }, {once: true});
        }
    },

    // Move the agents according to 'next' function
    moveAgents(next, force = true) { // !!
      this.agentList.forEach((a,i) => { 
        if (next(a)){ // If the result is not null... 

          let nextLocation = null
          let coords = {x: 0, y: 0}
          
          if (typeof next(a) === "object"){ // If it's an object, assume it's the coordinates
            nextLocation = null;
            coords = next(a)
          } else { // If it it's a string or something else, most likely the name of the next location 
            nextLocation = next(a) // Get name of location of where to move
            coords = this.getCoords(nextLocation) // Get coordinates (if no match with location list, will return random x, y in bounding box)
          } 
          a.loc = nextLocation || null; // "none"

          // a.setCoords(coords); // Set x, y coordinates of agent object

          // Save x, y coordinates into agentPositions to actually move the DOM elements (v-bound above)
          let loc = this.locationList.find(loc => loc.name == a.loc)
          this.agentPositions[i].x = coords.x
          this.agentPositions[i].y = coords.y

        } 
      })

      // Use d3 force simulation to space out the people (adjusts agentPositions)
      if (this.positioning && force){
        this.positioning = this.positioning
          .force('collision', d3.forceCollide().radius(this.agentRadius).strength(0.25))
          .force('charge', d3.forceManyBody().strength(1).distanceMax(5))
          .alpha(1).alphaDecay(0.02).tick(10)
      }
      // Check for NaNs (because sometimes d3.force returns NaNs??)
      this.agentPositions.forEach((d,i) => {
        if (isNaN(d.x) || isNaN(d.y)){
          // console.log(this.agentList[i])
          let {x, y} = this.getCoords(this.agentList[i].loc)
          this.agentPositions[i].x = x
          this.agentPositions[i].y = y
        }
      })
    },

    reset() { // !!
      this.agentList = this.generateStartingAgents();
      this.step(0, {infect: false})
    },

    // These functions are to add/remove agents, but it's better to hide/ unhide
    // addAgent({location = "shelter", status = "susceptible"} = {}) { // !!
    //   const newAgent = new Agent({id: this.agentList.length, loc: location, status: status})
    //   this.agentList.push(newAgent)
    //   this.agentPositions.push({x: null, y: null})
    // },

    // removeAgent(id = this.agentList.length - 1){ // !!
    //   this.agentList.splice(id, 1)
    //   this.agentPositions.splice(id, 1)
    // }

    hideAgent(id = 0){ // !!
      this.agentList[id].hidden = true;
    },
    unhideAgent(id = 0){ // !!
      this.agentList[id].hidden = false;
    },
    unhideAllExcept(exception = "none"){ // !!
      this.agentList.forEach((a,i) => {if (i != exception){
                                          this.unhideAgent(i)
                                      }}
      )
    },
    infectAgent(id = 0){ // !!
      this.agentList[id].contract(1);
    },
    uninfectAgent(id = 0){ // !!
      this.agentList[id].resetInfection();
    },
    uninfectAllExcept(exception = "none"){ // !!
      // console.log(`uninfect all except ${exception}`)
      this.agentList.forEach((a,i) => {if (i != exception){
                                          a.resetInfection();
                                      }}
      )
    },
    toggleLocationLabels(setting){
      if (setting == "on"){
        this.displayLabels = true;
      } else {
        this.displayLabels = false;
      }
    },
    toggleCounter(setting){
       if (setting == "on"){
        this.displayCounter = true;
      } else {
        this.displayCounter = false;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#main-vis{
  transition: background-color 2s ease;
  max-height: calc(var(--vh, 1vh) * 100);
}

/* Added to fill whole screen with colour */
.vis-wrapper{
  transition: background-color 2s ease;
  height: calc(var(--vh, 1vh) * 100);
}

button {
  padding: 10px;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

</style>


